export const baseURL = import.meta.env.VITE_BASE_URL;//API地址
const h5Url = 'https://h5.szsow.com';//H5页面地址
const wss = import.meta.env.VITE_WSS_URL;//websocket地址  如果是http就是ws
// export const IMAGE_URL = 'https://pcai.wailikeji.com/renren-api/image';
//export const IMAGE_URL = 'https://wailikeji.oss-cn-beijing.aliyuncs.com';
export const IMAGE_URL = 'https://sowai.oss-cn-shenzhen.aliyuncs.com';
export const LOGO_URL =
    "https://sowai.oss-cn-shenzhen.aliyuncs.com/chatgpt/20240810/f094f77495784fb78e59f3cf147a8fdf.png"
export const PC_LOGO_URL = "https://sowai.oss-cn-shenzhen.aliyuncs.com/chatgpt/20240810/f094f77495784fb78e59f3cf147a8fdf.png"
export const SHARE_SPLITER = import.meta.env.VITE_BASE_URL_SHARE_SPLITTER

export default { baseURL, wss, h5Url, IMAGE_URL, SHARE_SPLITER }