import { request } from '@/axios/index.js';

//验证码登录
export function smsLogin(data) {
  return request({
    url: '/api/oauth/smsLogin',
    method: 'post',
    data
  })
}

//发送验证码
export function sendSms(data) {
  return request({
    url: '/api/other/sendSms',
    method: 'post',
    data
  })
}

export function qrCreate(data) {
  return request({
    url: '/api/weChatGzh/getWechatQrcode',
    method: 'get',
    data
  })
}
// 二维码 key 生成接口
export function qrCheck(data) {
  return request({
    url: '/api/weChatGzh/checkLogin',
    method: 'post',
    data
  })
}

//查询热门问题
export function queryHotQs(data) {
  return request({
    url: '/api/hotQs/page',
    method: 'get',
    data
  })
}

//用户签到
export function SignIn(data) {
  return request({
    url: '/api/SignIn',
    method: 'get',
    data
  })
}

//用户信息
export function userInfo(data, toLogin) {
  return request({
    url: '/api/user/userInfo',
    method: 'get',
    data,
    toLogin
  })
}
export function getUserInfo(data) {
  return request({
    url: '/api/user/userInfo',
    method: 'get',
    data
  })
}
//上传图片
export function merChantupload(data) {
  return request({
    url: '/api/upload/merChantupload',
    method: 'post',
    data,
    header: {
      ContentType: 'multipart/form-data'
    }
  })
}
//更新用户信息
export function userUpdate(data) {
  return request({
    url: '/api/user/update',
    method: 'post',
    data,
  })
}

export function isTodaySign(data) {
  return request({
    url: '/api/isSign',
    method: 'get',
    data,
  })
}

export function drawImage(data) {
  return request({
    url: '/api/chat/draw',
    method: 'post',
    data,
  })
}

// 查询问答次数流水
export function querySpeciesList(data) {
  return request({
    url: '/api/billChangeGold/page',
    method: 'get',
    data
  })
}

//微信公众号登录
export function appWeChatLogin(data) {
  return request({
    url: '/api/oauth/appWeChatLogin',
    method: 'post',
    data
  })
}
//APP微信授权登录
export function mpWeChatLogin(data) {
  return request({
    url: '/api/oauth/mpWeChatLogin',
    method: 'post',
    data
  })
}
// 微信小程序登录
export function miniAppLogin(data) {
  return request({
    url: '/api/oauth/miniAppLogin',
    method: 'post',
    data
  })
}
//微信更新用户头像
export function wxUserInfo(data) {
  return request({
    url: '/api/oauth/wxUserInfo',
    method: 'post',
    data
  })
}
//我的团队
export function inviteRebateList(data) {
  return request({
    url: '/api/InviteRebateList',
    method: 'get',
    data
  })
}
//问答记录
export function queryQuestionAnswerList(data) {
  return request({
    url: '/api/questionAnswer/page',
    method: 'get',
    data
  })
}
//提现手续费
export function chargeScale(data) {
  return request({
    url: '/api/WithdrawalServiceChargeScale',
    method: 'get',
    data
  })
}

//申请提现
export function flowPath(data) {
  return request({
    url: '/api/withdrawalflowpath',
    method: 'post',
    data
  })
}

// 微信公众号登录获取公众号appid
export function getWxGZHAppid(data) {
  return request({
    url: '/api/oauth/getWxGZHAppid',
    method: 'get',
    data
  })
}

// 获取海报
export function getPoster(data) {
  return request({
    url: '/api/user/getPoster',
    method: 'get',
    data
  })
}
// 卡密兑换
export function CamilleExchange(data) {
  return request({
    url: '/api/CamilleExchange',
    method: 'post',
    data
  })
}

/**
 * 激活用户
 */
export function activeUser(data) {
  return request({
    url: '/api/user/active',
    method: 'post',
    data
  })
}

/**
 * 公告栏动态获取
 */
export const queryNotices = (data) => {
	return request({
		url: '/api/config/notice/list',
		method: 'get',
		data
	})
}

/**
 * 查询平台支持的大模型
 */
export const queryAllLlmTypeApi = (data) => {
	return request({
		url: '/api/config/getAllLlmType',
		method: 'get',
		data: {
      isOnlyGetCanUse: 1
    }
	})
}