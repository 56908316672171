<template>
    <el-row class="bg-white radius-12 px-8 pt-4">
        <el-col :span="12" :xs="8" :sm="13" :lg="15" :xl="17">
            <img @click="backToHome" v-if="$route.path === '/home/comfyui'" :src="PC_LOGO_URL" class="icon-logo" alt="">
            <img src="@/assets/img/laba.png" class="icon-laba" alt="">
            <div class="carouse-text inline-block">
                <span>{{ noticeStr }}</span>
            </div>
        </el-col>
        <el-col :span="12" :xs="16" :sm="11" :lg="9" :xl="7">
            <div class="right flex-row flex-end">
                <div @click="sign">每日签到</div>
                <div v-if="!userInfo.active" class="linear-text" @click="setActiveBoxVisible('2')">输入激活码</div>
                <div v-if="!userInfo.vip" @click="openVipBtn">开通会员</div>
                <el-popover placement="bottom-start" :width="200" trigger="hover" :hide-after="50">
                    <template #reference>
                        <div class="m-2">客户端下载</div>
                    </template>
                    <el-image
                        src="https://sowai.oss-cn-shenzhen.aliyuncs.com/static/qrcode_for_gh_f1a7ab12e2f3_258.jpg"></el-image>
                </el-popover>
                <div v-if="!userInfo.nickName" @click="login">登录</div>
                <el-popover popper-class="user-box" v-else placement="bottom" :width="300" trigger="hover">
                    <el-row justify="space-between" class="username mt-12">
                        <div>
                            <span class="font-bold mr-8">{{ userInfo.nickName }}</span>
                            <span class="mr-8">(用户ID: {{ userInfo.id }})</span>
                        </div>
                        <el-link @click="openVipBtn" type="warning" class="font-bold font-12 pointer"
                            style="color: #e6a23c;">续费
                            ></el-link>
                    </el-row>
                    <el-alert type="warning" title="" :closable="false" class="mt-8">
                        <el-tag v-if="userInfo.userRoleConfig" effect="dark" type="warning" class="tag">{{
                            userInfo.userRoleConfig.name
                            }}</el-tag>
                        有效期至：{{ userInfo.vipDate }}
                    </el-alert>
                    <div class="mt-8">
                        代理等级：
                        <el-text effect="dark" type="warning" class="tag">{{ userInfo.agentName }}</el-text>
                    </div>
                    <div class="mt-8">
                        <el-button class="func-item pointer" @click="loginOut">退出登录</el-button>
                    </div>
                    <template #reference>
                        <el-badge value="VIP" :hidden="!userInfo.vip" type="warning">
                            <el-avatar fit="contain" :size="32" class="pointer avatar-light-bg inline-block"
                                :src="addImagePrefix(userInfo.avatar)"></el-avatar>
                        </el-badge>
                    </template>
                </el-popover>
            </div>
        </el-col>
    </el-row>
    <loginDialog></loginDialog>
</template>

<script>
import loginDialog from './login.vue'
import { SignIn, activeUser, getUserInfo, queryNotices } from '@/http/http.js'
import { mapState, mapActions } from 'pinia'
import { globalData } from '@/store/store'
import { ACTIVE_MSG } from '@/view/home'
import { addImagePrefix } from '@/utils/utils';
import { PC_LOGO_URL } from '@/utils/env';

export default {
    data() {
        return {
            noticeStr: '网络不是法外之地，请注意您的言辞，如AI一直请求繁忙，请过段时间提问',
            addImagePrefix,
            PC_LOGO_URL
        }
    },
    components: {
        loginDialog
    },
    computed: {
        ...mapState(globalData, ['userInfo', 'token', 'activeBoxVisible'])
    },
    watch: {
        'activeBoxVisible': {
            handler() {
                this.showActiveMethodDialog()
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(globalData, ['setLoginVisible', 'setToken', 'setUserInfo', 'setActiveBoxVisible']),
        backToHome() {
            this.$router.push({ path: '/home/home' })
        },
        showActiveMethodDialog() {
            // 可能为字符串1，不要用全等符号
            if (!this.token || this.activeBoxVisible === '1') {
                return;
            }
            // 会员和已激活用户不弹窗
            if (this.userInfo && (!this.userInfo.active || !this.userInfo.vip)) {
                this.setActiveBoxVisible('1');
                ElMessageBox.prompt(ACTIVE_MSG, '提示', {
                    confirmButtonText: '激活',
                    cancelButtonText: '取消',
                    inputPattern: /[\S]{3,16}/,
                    inputErrorMessage: '请输入长度16位以内的激活码',
                    type: 'info',
                    dangerouslyUseHTMLString: true,
                    confirmButtonClass: 'linear-border',
                    customClass: 'waili-share-msg-box'
                }).then(({ value }) => {
                    activeUser({ code: value }).then(() => {
                        ElMessage.success('激活用户成功')
                    })
                }).catch(() => {
                    // 捕获cancel异常
                })
            }
        },
        login() {
            if (!this.userInfo.nickName) {
                this.setLoginVisible(true);
            }
        },
        loginOut() {
            this.setToken('')
            this.setUserInfo({})
            location.reload()
        },
        openVipBtn() {
            this.$router.push({ path: `/home/recharge` })
        },
        sign() {
            SignIn().then(res => {
                ElMessage.success(res.data);
            })
        },
        getNoticeList() {
            queryNotices().then(res => {
                const tempList = res.data.map(item => `${item.title}：${item.content}`)
                this.noticeStr = tempList.join('； ')
            })
        }
    },
    created() {
        getUserInfo().then(res => {
            this.setUserInfo(res.data)
        })
        this.getNoticeList();
    }
}
</script>

<style scoped lang="scss">
.icon-logo {
    width: 32px;
    line-height: 30px;
    vertical-align: super;
    margin-right: 5px;
}

.icon-laba {
    width: 20px;
    height: 20px;
    line-height: 30px;
    vertical-align: super;
    margin-right: 5px;
}

.carouse-text {
    color: $blue;
    width: calc(100% - 63px);
    position: relative;
    overflow: hidden;
    height: 30px;
    line-height: 30px;

    @keyframes move {
        0% {
            left: 100%
        }

        100% {
            left: -130%
        }
    }

    span {
        position: absolute;
        top: 0;
        animation: move 16s linear infinite;
    }
}

:deep .right {
    white-space: nowrap;
    margin-right: 10px;

    >div {
        margin-left: 12px;
        cursor: pointer;
        line-height: 33px;
        height: 33px;

        &:hover {
            color: $blue;
        }
    }

    .el-badge__content {
        font-size: 8px;
        top: 5px;
        opacity: .8;
    }
}
</style>

<style lang="scss">
.user-box {
    position: relative;
    margin-left: 0;

    .el-alert {
        --el-alert-padding: 8px;
    }

    .tag {
        height: 19px;
        padding: 4px 3px;
        border-radius: 12px;
        vertical-align: bottom;

        .el-tag__content {
            font-size: 10px;
        }
    }

    .func-item {
        width: 100%;
    }
}
</style>