<template>
    <el-dialog :model-value="loginVisible" :close-on-click-modal="false" :close-on-press-escape="false" title="登录"
        width="600px" class="login-dialog" @open="refreshQrcode">
        <!-- <el-icon class="block close" size="42" @click="closeLoginDialog">
      <i-ep-circle-close />
    </el-icon> -->
        <el-row class="dialog-content">
            <el-col :span="11" class="left">
                <div class="left-content">
                    <el-row>
                        <div class="flex-row flex-center">
                            <el-image :src="PC_LOGO_URL" fit="contain" style="width: 54px;"></el-image>
                            <div class="ml-10">
                                <div class="font-14">AInnoDesign</div>
                                <div class="font-12">深圳市四方智源科技有限公司</div>
                            </div>
                        </div>
                    </el-row>
                    <el-row justify="center" class="mt-32">
                        <span class="font-18">登录账号可以使用</span>
                    </el-row>
                    <el-row justify="center" class="mt-24 func-list">
                        <el-icon size="28"><i-ep-mostly-cloudy /></el-icon>
                        <span>记录云端同步</span>
                    </el-row>
                    <el-row justify="center" class="mt-20 func-list">
                        <el-icon size="28"><i-ep-picture-rounded /></el-icon>
                        <span>优秀作品参考</span>
                    </el-row>
                    <el-row justify="center" class="mt-20 func-list">
                        <el-icon size="28"><i-ep-cellphone /></el-icon>
                        <span>手机电脑通用</span>
                    </el-row>
                    <el-row justify="center" class="mt-20 func-list">
                        <el-icon size="28"><i-ep-coin /></el-icon>
                        <span>签到福利获取</span>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="13">
                <div class="user-login-box">
                    <el-row justify="center" class="tab-box">
                        <el-col :span="12" class="tab-item" v-for="(item, index) in tabList" :key="index"
                            :class="{ active: tabIndex === index }" @click="tabBtn(index)">{{ item.name }}</el-col>
                    </el-row>
                    <template v-if="tabIndex == 0">
                        <el-form :rules="rules" :model="form" ref="form" class="mt-42">
                            <el-form-item prop="mobile">
                                <el-input v-model="form.mobile" placeholder="手机号登录/注册"></el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <div class="code-box mt-12">
                                    <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
                                    <div class="code-btn c-9" @click="getCode">{{ codeStr }}</div>
                                </div>
                            </el-form-item>
                        </el-form>
                        <el-row justify="center">
                            <el-button size="large" plain type="primary" class="mt-20"
                                @click="phoneLogin">登录/注册</el-button>
                        </el-row>
                    </template>
                    <template v-else>
                        <el-row justify="center" class="weixin-code-box mt-24">
                            <div class="canvas-code-box">
                                <canvas ref="canvas"></canvas>
                                <el-row justify="center" class="c-9">
                                    <span>微信扫码可完成注册和登录</span>
                                </el-row>
                                <div v-if="isRefresh">
                                    <el-row justify="center" class="c-9 mt-10">
                                        <span>二维码已失效</span>
                                    </el-row>
                                    <el-row justify="center" class="c-9 mt-10">
                                        <el-button color="#63acb6" plain type="primary"
                                            @click="refreshQrcode">点击刷新</el-button>
                                    </el-row>
                                </div>
                            </div>
                        </el-row>
                    </template>
                </div>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script lang="ts">
import { regPhone } from '@/utils/utils.js';
import QRCode from 'qrcode'
import { sendSms, smsLogin, qrCreate, qrCheck } from '@/http/http.js';
import { globalData } from '@/store/store.js'
import { mapState, mapActions } from 'pinia';
import { PC_LOGO_URL } from '@/utils/env';

export default {
    name: 'login',
    data() {
        return {
            PC_LOGO_URL,
            form: {
                mobile: '',
                code: '',
            },
            tabList: [{ name: '手机号登录' }, { name: '微信公众号登录' }],
            tabIndex: 1,
            rules: {
                mobile: [{
                    trigger: 'blur', validator: (rules, value, call) => {
                        if (!value) {
                            call(new Error("请输入手机号码"))
                        } else if (!regPhone(value)) {
                            call(new Error("请输入正确的手机号码"))
                        } else {
                            call();
                        }
                    }
                },
                ],
                code: [{
                    trigger: 'blur', validator: (rules, value, call) => {
                        if (!value) {
                            call(new Error("请输入验证码"))
                        } else if (!/^\d{1,8}$/.test(value)) {
                            call(new Error("验证码由数字组成"))
                        } else {
                            call();
                        }
                    }
                }]
            },
            codeStr: '获取验证码',
            seconds: 10,
            codeTimer: null,
            isCode: true,
            //扫码登录
            url: '',
            key: '',
            timer: null,
            isRefresh: true,
            isLoading: false,
        }
    },
    computed: {
        ...mapState(globalData, ['loginVisible', 'userInfo'])
    },
    // unmounted() {
    // window.addEventListener('keydown', this.enterLogin);
    //   this.removeKeyDownListener()
    // },
    methods: {
        ...mapActions(globalData, ['setUserInfo', 'setToken', 'setLoginVisible', 'setActiveBoxVisible']),
        enterLogin(e) {
            if (e.keyCode == 13 && this.tabIndex === 0 && !this.userInfo.nickName) {
                this.phoneLogin()
            }
        },
        closeLoginDialog() {
            // this.removeKeyDownListener()
            this.setLoginVisible(false)
        },
        removeKeyDownListener() {
            window.removeEventListener('keydown', this.keyDown, false);
        },
        tabBtn(index) {
            this.tabIndex = index;
            if (index === 1) {
                this.createQrCode()
            } else {
                this.stop();
            }
        },
        loginSuccessPost(data) {
            // this.removeKeyDownListener()
            ElMessage.success('登录成功');
            this.setToken(data.token)
            this.setUserInfo(data);
            this.form = {
                mobile: '',
                code: '',
            }
            this.setLoginVisible(false);
            location.reload();
        },
        phoneLogin() {
            this.$refs.form.validate((valid) => {
                if (!valid) return;
                let pid = localStorage.pid || '';
                this.form.pid = pid;
                smsLogin(this.form).then(res => {
                    this.loginSuccessPost(res.data)
                })
            })
        },
        async getCode() {
            let mobile = this.form.mobile;
            if (!mobile) {
                return ElMessage.error('请输入手机号！')
            } else if (!regPhone(mobile)) {
                return ElMessage.error('请输入正确的手机号！')
            } else if (!this.isCode) {
                return;
            }
            this.isCode = false;
            sendSms({ mobile }).then(res => {
                ElMessage.success('验证码发送成功');
                this.codeTimer = setInterval(() => {
                    let seconds = this.seconds;
                    if (seconds <= 0) {
                        this.isCode = true;
                        this.seconds = 60;
                        this.codeStr = '重新获取';
                        clearInterval(this.codeTimer);
                    } else {
                        seconds = seconds - 1;
                        this.codeStr = seconds + 's重新获取';
                        this.seconds = seconds;
                    }
                }, 1000)
            })
        },
        // 获取二维码地址
        createQrCode() {
            this.isLoading = true;
            let pid = localStorage.pid || '';
            qrCreate({ "pid": pid }).then(res => {
                this.url = res.data.qrcodeUrl
                this.key = res.data.code
                this.makeQrCode()
            })
        },
        // 生成二维码
        makeQrCode() {
            let opts = {
                errorCorrectionLevel: 'H',
                type: 'image/png',
                quality: 0.3,
                width: 165,
                height: 165,
                text: 'xxx',
                color: {
                    dark: '#333333',
                    light: '#fff'
                }
            }
            QRCode.toCanvas(this.$refs.canvas, this.url, opts)
            this.isLoading = false;
            this.lunxunData()
        },
        // 检查二维码状态
        async checkQrCode() {
            // 节流
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            qrCheck({ code: this.key }).then(res => {
                if (res.code === 0) {
                    if (res.data.loginOk) {
                        this.stop();
                        this.loginSuccessPost(res.data)
                    }
                } else {
                    this.isRefresh = true
                    this.stop();
                }
                this.isLoading = false;
            }).catch(() => {
                this.isRefresh = true
                this.stop();
                this.isLoading = false;
            })
        },
        // 轮询
        lunxunData() {
            // 避免反复设置
            if (this.timer) {
                return;
            }
            this.timer = setInterval(() => {
                this.checkQrCode()
            }, 3000)
        },
        // 停止轮询
        stop() {
            clearInterval(this.timer)
            this.timer = null
        },
        // 刷新二维码
        refreshQrcode() {
            // 关闭二维码过期样式
            this.isRefresh = false
            this.createQrCode()
        },
    },
}
</script>
<style lang="scss">
.login-dialog {
    position: relative;
    border-radius: 8px !important;
}
</style>
<style scoped lang="scss">
.close {
    position: absolute;
    right: -40px;
    top: -40px;
    color: $white;
    cursor: pointer;

    svg {
        width: 28px;
        height: 28px;
    }
}

.dialog-content {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
    height: 415px;
    width: 600px;

    .left {
        background: url('../../assets/img/loginBg.png') no-repeat;
        background-size: contain;
        background-color: #3975f9;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 8px;
        color: $white;
        filter: grayscale(10%);

        .left-content {
            padding: 16px 24px;

            .func-list {
                span {
                    line-height: 28px;
                    font-weight: lighter;
                    margin-left: 5px;
                }
            }

            svg {
                width: 22px;
                height: 22px;
            }
        }
    }
}

.user-login-box {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid $border-gray;
    background-color: #FFF;
    height: 100%;
    padding: 68px 12px;
}

.code-box {
    display: flex;
    width: 300px;
}

.tab-box {
    display: flex;
    margin-bottom: 12px;
    text-align: center;

    .tab-item {
        padding: 0 4px 4px;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        position: relative;

        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            bottom: -2px;
            transform: translateX(-50%);
            background-color: #999999;
            height: 1px;
            width: 0;
            transition: width .2s;
        }

        &:hover {
            &::after {
                width: 100%;
                height: 2px;
                background-color: var(--el-color-primary-light-8);
            }
        }

        &.active {
            &::after {
                width: 100%;
                height: 2px;
                background-color: var(--el-color-primary);
            }
        }
    }
}

.code-btn {
    width: 110px;
    text-align: right;
    cursor: pointer;

    &:hover {
        color: $theme-color;
    }
}
</style>