import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home/home',
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/home',
    component: () => import('../view/layout/layout.vue'),
    children: [
      {
        path: '/home/home',
        name: 'homeIndex',
        component: () => import('../view/home/home.vue'),
      },
      {
        path: 'comfyui',
        name: "comfyui",
        component: () => import('../view/paint/components/comfyUI.vue')
      },
      {
        path: 'paint',
        name: 'homePaint',
        component: () => import('../view/paint/paintTab.vue'),
        children: [
          {
            path: 'applicationList',
            name: "applicationList",
            component: () => import('../view/paint/components/applicationList.vue')
          },
          {
            path: 'applicationDetail',
            name: "applicationDetail",
            component: () => import('../view/paint/components/applicationDetail.vue')
          },
          {
            path: 'startPaint/1',
            name: "sdTextPaint",
            component: () => import('../view/paint/components/application/paint.vue')
          },
          {
            path: 'startPaint/2',
            name: "sdImgPaint",
            component: () => import('../view/paint/components/application/paint.vue')
          },
          {
            path: 'startPaint/3',
            name: "dallePaint",
            component: () => import('../view/paint/components/application/dallePaint.vue')
          },
          {
            path: 'startPaint/5',
            name: "qrCodePaint",
            component: () => import('../view/paint/components/application/qrCodePaint.vue')
          },
          {
            path: 'startPaint/6',
            name: "img2VideoPaint",
            component: () => import('../view/paint/components/application/imgToVideoPaint.vue')
          },
          {
            path: 'startPaint/7',
            name: "expandPaint",
            component: () => import('../view/paint/components/application/expandPaint.vue')
          },
          {
            path: 'startPaint/8',
            name: "facePaint",
            component: () => import('../view/paint/components/application/facePaint.vue')
          },
          {
            path: 'startPaint/11',
            name: "clothesPaint",
            component: () => import('../view/paint/components/application/clothesPaint.vue')
          },
          {
            path: 'startPaint/1021',
            name: "threeDimensionPaint",
            component: () => import('../view/paint/components/application/threeDimensionPaint.vue')
          },
          {
            path: 'startPaint/1014',
            name: "expandPaintNew",
            component: () => import('../view/paint/components/application/expandPaintNew.vue')
          },
          {
            path: 'startPaint/1015',
            name: "textDrawNew",
            component: () => import('../view/paint/components/application/textPaint.vue')
          },
          {
            path: 'startPaint/customWorkflow/:number',
            name: "customWorkflow",
            component: () => import('../view/paint/components/application/customWorkflow.vue')
          },
          {
            path: 'paintList',
            name: "paintList",
            component: () => import('../view/paint/components/paintList.vue')
          },
          {
            path: 'myPaint',
            name: "myPaint",
            component: () => import('../view/paint/components/myPaint.vue')
          },
          {
            path: 'startPaint/createWorkflow',
            name: "createWorkflow",
            component: () => import('../view/paint/workflow/createWorkflow.vue')
          },
        ]
      },
      {
        path: '/home/chatpdf',
        name: 'chatpdf',
        component: () => import('../view/chatpdf/chatpdf.vue'),
      },
      {
        path: '/home/robots',
        name: 'robots',
        component: () => import('../view/robots/robots.vue'),
      },
      {
        path: '/home/issureLog',
        name: 'issureLog',
        component: () => import('../view/issureLog/issureLog.vue'),
      },
      {
        path: '/home/coinLog',
        name: 'coinLog',
        component: () => import('../view/coinLog/coinLog.vue'),
      },
      {
        path: '/home/recharge',
        name: 'recharge',
        component: () => import('../view/recharge/recharge.vue'),
      },
      {
        path: '/home/user',
        name: 'userCenter',
        component: () => import('../view/user/user.vue'),
      },
      {
        path: '/home/shortVideo',
        name: 'shortVideo',
        component: () => import('../view/shortVideo/shortVideo.vue'),
      },
      {
        path: '/home/virtualRobot',
        name: 'virtualRobot',
        component: () => import('../view/virtualRobot/virtualRobot.vue'),
      },
      {
        path: '/home/feedback',
        name: 'feedback',
        component: () => import('../view/feedback/feedback.vue'),
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../view/about/about.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
